import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["body", "searchInput"]
	className = "article"

	enableSaveButton(e) {
		let submitButton = e.target.parentElement.parentElement.parentElement.querySelector("[type='submit']")
		submitButton.removeAttribute('disabled')
		submitButton.classList.remove('btn-primary')
		submitButton.classList.add('btn-warning')
	}

	confirmDeletion(e) {
		if (!confirm("Sicher?")) {
			e.preventDefault()
		}
	}

	resetSaveButton() {
		this.element.addEventListener('turbo:submit-end', e => {
			let btn = document.querySelectorAll('.btn-warning')
			btn.forEach (b => {
				b.classList.remove('btn-warning')
				b.classList.add('btn-primary')
				b.setAttribute('disabled','disabled')
			})
		}, {
		  once: true,
		})
	}

	openSearch(e) {
		document.querySelectorAll('.selection-target').forEach(el => { el.classList.remove('selection-target') })
		e.currentTarget.parentElement.parentElement.parentElement.classList.add('selection-target')
		this.element.addEventListener('shown.bs.modal', e => {
			this.searchInputTarget.focus()
		}, {
		  once: true,
		})
	}

	keyupHandler(e) {
		if (this.searchInputTarget.value == "" || this.searchInputTarget.value.length < 3) return;
		if (e.which != 224 && e.which != 17 && e.which != 19 && e.which != 18 && e.which != 16 && e.which !== 0 && !e.ctrlKey && !e.metaKey && !e.altKey) {
			this.search()
		}
	}

	classChanged(e) {
		this.className = e.target.value
	}

	search() {
		if (this.className == 'paragraph') {
			this.url = `/api/paragraph_anchors.json?query=${this.searchInputTarget.value}`}
		if (this.className == 'article') {
			this.url = `/articles/search.json?query=${this.searchInputTarget.value}`}
		
		fetch(this.url)
			.then(response => response.json())
			.then(data => this.bodyTarget.innerHTML = this.template(data));
	}

	select(e) {

		let el = e.currentTarget
		let target = this.getTarget()
		if (!target) { return false }

		if (this.className == 'paragraph') {
			target.querySelector('#menu_item_link').value = el.dataset.articleLink
			target.querySelector('#menu_item_title_de').value = el.dataset.titleDe
			target.querySelector('#menu_item_title_en').value = el.dataset.titleEn
			target.querySelector('#menu_item_title_nl').value = el.dataset.titleNl
		}

		if (this.className == 'article') {
			this.url = `/articles/${el.id}.json`
			fetch(this.url)
				.then(response => response.json())
				.then(data =>{
					target.querySelector('#menu_item_link').value = data.link
					target.querySelector('#menu_item_title_de').value = data.title_de
					target.querySelector('#menu_item_title_en').value = data.title_en
					target.querySelector('#menu_item_title_nl').value = data.title_nl
					
				});
		}

		let submitButton = target.querySelector("[type='submit']")
		submitButton.removeAttribute('disabled')
		submitButton.classList.remove('btn-primary')
		submitButton.classList.add('btn-warning')
	}

	getTarget() {
		return document.getElementsByClassName('selection-target')[0]
	}

	template(items) {
		if (this.className == "article") return this.render_articles(items);
		if (this.className == "paragraph") return this.render_paragraphs(items);
		return ""
	}

	render_articles(items) {
		var items_html = "";
		items.forEach( item => {
		  	items_html += "<div data-bs-dismiss='modal' class='linked_item_cms_preview' data-action='click->menuitems#select' id='" + item.id + "' >"
		  	items_html += "<div>"
	  		if (item.icon !== undefined) {
	  			items_html += "<img src='" + item.icon + "'>"
	  		} else {
	  			items_html += "<img src='/touch-icon-iphone-retina.png'>" }
		  	items_html += "</div>";
		  	items_html += "<div class='linked_item_cms_preview_text_container'><span>" + item.level + "</span>";
		  	items_html += "<b>"+item.text+"</b>";
		  	items_html += "<br>"+item.subtitle;
		  	if(item.start_at !== undefined) {items_html += "<br>" + item.a_type+ ": " + item.start_at;}
		  	items_html += "</div></div>";
		});
		return items_html;
	}

	render_paragraphs(items) {
		var items_html = "";
		items.forEach( item => {
		  	items_html += "<div data-bs-dismiss='modal' class='linked_item_cms_preview' data-action='click->menuitems#select' id='" + item.id + "' "
		  	items_html += ` data-article-link="${item.article_link}" `
		  	items_html += ` data-title-de="${item.title_de}" `
		  	items_html += ` data-title-en="${item.title_en}" `
		  	items_html += ` data-title-nl="${item.title_nl}" `
		  	items_html += ">"
			  	items_html += "<div class='linked_item_cms_preview_text_container'>";
			  	items_html += "<b>Artikel: " + item.article_title + "</b>";
			  	items_html += "<br> ⤷ Absatz: " + item.title_de;
		  	items_html += "</div></div>";
		});
		return items_html;
	}
}