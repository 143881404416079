import { Controller } from "stimulus"
import imagesLoaded from 'imagesloaded'

export default class extends Controller {

  connect() {
    var allItems = document.querySelectorAll('.masonry-item');
    if (allItems) {
      this.listenForImagesLoadedAndResize(allItems)
    }
  }

  listenForImagesLoadedAndResize(items) {
    items.forEach(i => {
      imagesLoaded(i, function(instance) {
        var item = instance.elements[0]
        this.resizeMasonryItem(item)
      }.bind(this));
    })
  }

  fetchAppend(e) {
    let href = new Request(e.target.dataset.href)
    
    let appendButton = document.getElementById('masonry-fetch-append')
    if (appendButton) { appendButton.remove() }

    fetch(href)
      .then(function(response) {
          return response.text()
      })
      .then(html => { 
        let parser = new DOMParser();
        let doc = parser.parseFromString(html, 'text/html');
        let grid = document.getElementById('masonry');
        let newItems = doc.querySelectorAll('.masonry-item')
        newItems.forEach(i => {grid.append(i)})
        this.listenForImagesLoadedAndResize(newItems)

        let newAppendButton = doc.getElementById('masonry-fetch-append')
        if (newAppendButton) { this.element.append(newAppendButton) }
      })
  }

  fetchReplace(e) {
    let href = new Request(e.target.dataset.href)
    let btns = document.querySelectorAll('.masonry-filter-btn')
    btns.forEach(i => { i.classList.remove('active') })
    
    // remove appending-button since its replacing OR appending
    let appendButton = document.getElementById('masonry-fetch-append')
    if (appendButton) { appendButton.remove() }
    
    e.target.classList.add('active')
    fetch(href)
      .then(function(response) {
          return response.text()
      })
      .then(html => { 
        let parser = new DOMParser();
        let doc = parser.parseFromString(html, 'text/html');
        let grid = document.getElementById('masonry');
        let newItems = doc.querySelectorAll('.masonry-item')
        grid.innerHTML = "";
        newItems.forEach(i => { grid.append(i) })
        this.listenForImagesLoadedAndResize(newItems)

        // if 'all' is selected we expect a appendButton and we are back in appending-mode
        let newAppendButton = doc.getElementById('masonry-fetch-append')
        if (newAppendButton) { this.element.append(newAppendButton) }
      })
  }

  resizeMasonryItem(item){
    var grid = document.getElementById('masonry');
    if(grid) {
      var rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap')),
          rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows')),
          gridImagesAsContent = item.querySelector('img.masonry-content');
      if (rowGap == 0) return;
      /*
       * Spanning for any brick = S
       * Grid's row-gap = G
       * Size of grid's implicitly create row-track = R
       * Height of item content = H
       * Net height of the item = H1 = H + G
       * Net height of the implicit row-track = T = G + R
       * S = H1 / T
       */
      var rowSpan = Math.ceil((item.querySelector('.masonry-content').getBoundingClientRect().height+rowGap) / (rowHeight+rowGap));

      /* Set the spanning as calculated above (S) */
      item.style.gridRowEnd = 'span ' + rowSpan;
      if (gridImagesAsContent) {
        item.querySelector('img.masonry-content').style.height = item.getBoundingClientRect().height + "px";
      }
    }
  }

  resizeAllMasonryItems(){
    let allItems = document.querySelectorAll('.masonry-item');
    if (allItems) {
      for(let i=0; i>allItems.length; i++){
        resizeMasonryItem(allItems[i]);
      }
    }
  }
}