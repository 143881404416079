import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["description", "playPauseIcon", "playPauseBtn", "progress", "slider", "currentTime", "duration", "pin"]
  // static values = { url: String }
  audioplayer = null

  initialize() {
    this.mover = this.mover.bind(this)
    this.makePlayable = this.makePlayable.bind(this)
    this.makeStop = this.makeStop.bind(this)
    this.setDuration = this.setDuration.bind(this)
    this.mouseRewinding = this.mouseRewinding.bind(this)
    this.touchRewinding = this.touchRewinding.bind(this)
    this.update = this.update.bind(this)
  }
  
  connect() {
    this.audioplayer = document.getElementById('audioplayer')
    if (this.audioplayer != null) {
      this.audioplayer.addEventListener('canplay', this.makePlayable)
      this.audioplayer.addEventListener('timeupdate', this.update)
      this.audioplayer.addEventListener('loadedmetadata', this.setDuration)
      this.audioplayer.addEventListener('ended', this.makeStop)

      this.pinTarget.addEventListener('mousedown', this.mouseRewinding)
      this.pinTarget.addEventListener('touchstart', this.touchRewinding)

      // start von vorheriger seite
      if (this.audioplayer.readyState > 2) {
        this.setDuration()
        if (!this.audioplayer.paused) {
          this.playPauseIconTarget.attributes.d.value = "M0 0h6v24H0zM12 0h6v24h-6z";
        }
      // start aus playlist am absatz
      } else {
        if (this.element.dataset.startUrl != null) {
          this.audioplayer.setAttribute('src', this.element.dataset.startUrl)
        }
      }
    }
    


    // TODO Implement CLick on slider to change Pos
    // this.sliderTarget.addEventListener('click', this.mover)
  }

  makePlayable() {
    if (this.audioplayer.paused) {
      this.playPauseIconTarget.attributes.d.value = "M18 12L0 24V0";
    } else {
      this.playPauseIconTarget.attributes.d.value = "M0 0h6v24H0zM12 0h6v24h-6z";
    }
    this.playPauseBtnTarget.style.display = 'block';
  }

  setSpeed(event) {
    const pa = this.audioplayer
    const speed = parseFloat(event.currentTarget.dataset.speed)
    if (pa.paused) { return }
    const speedButtons = document.querySelectorAll('.player-speed-buttons')

    speedButtons.forEach(function(el){
        el.classList.remove('active')
    })
    if (pa.playbackRate === speed) {
        pa.playbackRate = 1
    } else {
        pa.playbackRate = speed
        event.currentTarget.classList.add('active')
    }
  }

  mouseRewinding(event) {
      if (this.pinTarget != event.originalTarget) return false;
      window.addEventListener('mousemove', this.mover, false);
      window.addEventListener('mouseup', function() {
        window.removeEventListener('mousemove', this.mover, false);
      }.bind(this), false);
  }

  touchRewinding(event) {
      if (this.pinTarget != event.originalTarget) return false;
      window.addEventListener('touchmove', this.mover, false);
      window.addEventListener('touchend', function() {
        window.removeEventListener('touchmove', this.mover, false);
      }.bind(this), false);  
  }

  mover(event){
    if (this.inRange(event)) {  
      this.audioplayer.currentTime = this.audioplayer.duration * this.getCoefficient(event);
    }
  }

  inRange(event) {
    let touch = ('touches' in event)
    let rangeBox = this.getRangeBox(event);
    let sliderPositionAndDimensions = rangeBox.getBoundingClientRect();
    let min = sliderPositionAndDimensions.x;
    let max = min + sliderPositionAndDimensions.width;
    let clientX = touch ? event.touches[0].clientX : event.clientX;
    if (clientX < min || clientX > max) return false;      
    return true;
  }

  formatTime(time) {
    let min = Math.floor(time / 60);
    let sec = Math.floor(time % 60);
    return min + ':' + ((sec<10) ? ('0' + sec) : sec);
  }

  setDuration() {
    this.durationTarget.textContent = this.formatTime(this.audioplayer.duration);
  }

  getRangeBox(event) {
    let rangeBox = event.target;

    if (event.type == 'mousemove') {
      rangeBox = this.pinTarget.parentElement.parentElement;
    }
    if (event.type === 'touchmove') {
      rangeBox = this.pinTarget.parentElement.parentElement;
    }
    return rangeBox;
  }

  getCoefficient(event) {
    let touch = ('touches' in event)
    let slider = this.getRangeBox(event, this.pinTarget);
    let sliderPositionAndDimensions = slider.getBoundingClientRect();
    let K = 0;
    let clientX = touch ? event.touches[0].clientX : event.clientX;
    let offsetX = clientX - sliderPositionAndDimensions.left;
    let width = sliderPositionAndDimensions.width;
    K = offsetX / width;    
    return K;
  }

  togglePlay(e) {
    const item = e.currentTarget
    if (this.audioplayer.paused) {
      if (this.audioplayer.getAttribute("src") == "") {
        this.audioplayer.setAttribute('src', item.dataset.audiosrc)
      }
      this.playPauseIconTarget.attributes.d.value = "M0 0h6v24H0zM12 0h6v24h-6z";
      this.audioplayer.play();
    } else {
      this.playPauseIconTarget.attributes.d.value = "M18 12L0 24V0";
      this.audioplayer.pause();
    }  
  }

  update() {
    let current = this.audioplayer.currentTime;
    let percent = (current / this.audioplayer.duration) * 100;
    this.progressTarget.style.width = percent + '%';
    this.currentTimeTarget.textContent = this.formatTime(current);
  }

  makeStop() {
    this.audioplayer.currentTime = 0
    this.playPauseIconTarget.attributes.d.value = "M18 12L0 24V0";
  }

  playListItemClicked(e) {
    const item = e.currentTarget
    this.makeStop()
    this.audioplayer.setAttribute('src', item.dataset.audiosrc)
    this.descriptionTarget.innerHTML = item.dataset.description
    this.audioplayer.addEventListener('canplay', this.togglePlay.bind(this), {
      once: true
    })

    this.playPauseBtnTarget.style.display = 'none';
  }
}
