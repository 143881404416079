import { Controller } from "stimulus"
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
require('flatpickr/dist/plugins/monthSelect/style.css')

export default class extends Controller {
  static targets = ["monthInput", "results", "month", "types", "resetButton", "filterCategory"]
  flatpickrInstance = null

  connect() {
    this.flatpickrInstance = flatpickr('#datepicking-months', {
    altInput: false,
    plugins: [
        new MonthSelectPlugin({
          shorthand: true,
          dateFormat: "Y-m-01",
          altFormat: "F Y"
        })
    ]
    })
  }

  getLocale() {
    return this.element.dataset.locale
  }

  selectMonth(e) {
    if (e.target.value == "") { return }
    const results = this.resultsTarget
    const xhttp = new XMLHttpRequest()
    this.typesTarget.style.display = "none"
    this.resetButtonTarget.style.display = "block"

    xhttp.onload = function() {
      results.innerHTML = xhttp.response
    }

    xhttp.open("GET", "/events?layout=snippet&month_from=" + e.target.value + "&locale=" + this.getLocale())
    xhttp.send()
  }

  reset(e) {
    const results = this.resultsTarget
    const xhttp = new XMLHttpRequest()
    this.typesTarget.style.display = "block"
    this.resetButtonTarget.style.display = "none"
    this.flatpickrInstance.clear()
    this.resetCategoryFilter()
    this.monthInputTarget.style.display = "block"

    xhttp.onload = function() { results.innerHTML = xhttp.response }
    xhttp.open("GET", "/events?layout=snippet&locale=" + this.getLocale())
    xhttp.send()
  }

  resetCategoryFilter() {
    this.filterCategoryTargets.forEach((ft) => {
      ft.removeAttribute('disabled');
    })
  }

  selectCategory(e) {
    const selectedFilter = e.target
    const results = this.resultsTarget
    const xhttp = new XMLHttpRequest()
    const monthInput = this.monthInputTarget
    const resetButton = this.resetButtonTarget

    this.resetCategoryFilter()

    if (selectedFilter.dataset.type) {
      selectedFilter.setAttribute('disabled', 'disabled');

      xhttp.onload = function() {
        results.innerHTML = xhttp.response
        monthInput.style.display = "none"
        resetButton.style.display = "block"
      }

      xhttp.open("GET", "/events?layout=snippet&event_type=" + selectedFilter.dataset.type + "&locale=" + this.getLocale())
      xhttp.send()
    }
  }
}