import { Controller } from "stimulus"
//import { navigator } from "@hotwired/turbo"
/*
 * Die Checkbox schaltet den Privacy-Btn frei
 * Standard: Der Privacy-Btn ist ein Submit-Button auf ein
 * Formular. 
 * In Anwendungen kann eine der unteren JS Fkt. benutzt werden
 */

export default class extends Controller {
  static targets = ["checkbox", "proceedBtn"];

	open() {
    this.resetCheckboxAndButton()
  }

  close() {
    this.resetCheckboxAndButton()
  }

  resetCheckboxAndButton() {
    this.checkboxTarget.checked = false
    this.proceedBtnTarget.setAttribute('disabled', 'disabled')
  }

  confirmedChanged(event) {
    if (this.checkboxTarget.checked) {
      this.proceedBtnTarget.attributes.removeNamedItem('disabled')

      const input = document.createElement("input")
      input.id = "privacy_confirmed"
      input.type = "hidden"
      input.name = "privacy_confirmed"
      input.value = "on"
      this.element.parentElement.appendChild(input)
    } else {
      this.proceedBtnTarget.setAttribute('disabled', 'disabled')
      this.element.parentElement.querySelector('#privacy_confirmed').remove()
    }
  }

  //TODO als form bauen und über turboStream schicken
  closeBootstrapModals(e) {
    const close_btns = document.querySelectorAll("[data-bs-dismiss='modal']");
    const body = document.getElementsByTagName('body')[0]
    const modal_backdrops = document.querySelectorAll('.modal-backdrop')
    
    close_btns.forEach(b => { b.click() })
    body.classList = ""
    body.style = ""
    modal_backdrops.forEach(mb => { mb.remove() })
    this.resetCheckboxAndButton()
    // Needed in Safari and Chrome
    if (navigator.userAgent.indexOf("Firefox") == -1) {
      this.proceedBtnTarget.closest('form').requestSubmit()
    }
  }

  // TODO Diesen Prozess sollte man mit Turbo Frame machen, weils nur ein Fomular ist
  sendRegistration() {
    const form = document.getElementById('registration_form')
    const form_data = new FormData(form)
    const locale  = document.getElementById('registration_locale').value
    const xhttp = new XMLHttpRequest()

    xhttp.onload = function() {
      form.innerHTML = xhttp.response
      this.closeBootstrapModals()
    }.bind(this)

    //TODO privacy_confirmed als "on" übergeben und im rails controller auch so prüfen
    xhttp.open("POST", "/api/event_registration?locale=" + locale + "&privacy_confirmed=" + (this.checkboxTarget.checked == true ? "true" : "false"));
    xhttp.send(form_data)
  }

  //TODO als form bauen und über turboStream schicken
  sendParagraphEmail() {
    this.proceedBtnTarget.setAttribute('disabled', 'disabled')

    const sender_email = document.getElementById('paragraph_contact_sender_email')
    const sender_name = document.getElementById('paragraph_contact_sender_name')
    const sender_text = document.getElementById('paragraph_contact_sender_text')
    const paragraph_id = document.getElementById('paragraph_contact_paragraph_id')
    const xhttp = new XMLHttpRequest()

    xhttp.onload = function() {
      this.closeBootstrapModals()
      try {
        const json_response = JSON.parse(xhttp.response)
        if (json_response.success == true) {
          alert('Erfolgreich versendet. Vielen Dank für Ihre Anfrage. | Email was send. Thanks!');
        } else {
          alert('Email konnte nicht gesendet werden. | Email could not be delivered.');
        }
      } catch (e) {
        if (e instanceof SyntaxError) {
          alert('Email konnte nicht gesendet werden. | Email could not be delivered.');
        }
      }
    }.bind(this)

    xhttp.open("POST", "/api/email_contact/");
    xhttp.setRequestHeader("Content-Type", "application/json;");
    //TODO privacy_confirmed als "on" übergeben und im rails controller auch so prüfen
    xhttp.send(JSON.stringify(
      {
        privacy_confirmed: (this.checkboxTarget.checked == true ? "true" : "false"),
        paragraph_id: paragraph_id.value,
        sender_email: sender_email.value,
        sender_name: sender_name.value,
        sender_text: sender_text.value
      }
    ));
    sender_email.value = ""
    sender_name.value = ""
    sender_text.value = ""
    paragraph_id.value = ""
  }
}