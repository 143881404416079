import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["body", "searchInput", "attachmentTypesFilter"];

  url = ""
  className = ""
  attachmentFilterType = ""
  articleFilterType = ""
	recievingElement = null
	previews = null

  setup() {
  	const button = event.relatedTarget;
		this.recievingElement = button.closest('.linked_item_menu')
		this.recievingElement.setAttribute('id','recieving_linked_item_menu')
		this.previews = this.recievingElement.querySelectorAll('.linked_item_menu_previews')[0]
		this.className = button.dataset.className
		let queryData = {};

		this.bodyTarget.innerHTML = ""
		this.searchInputTarget.value = ""

		if (this.className == "attachment") {
			this.attachmentTypesFilterTarget.style.display = 'block'
			const preselection = button.dataset.attachmentFilterPreselection
			if (preselection) {
				this.attachmentFilterType = preselection
				document.querySelector('input[name=modal_selection_attachment_type][value=' + preselection + ']').checked = true
			}
		} else {
			this.attachmentTypesFilterTarget.style.display = 'none'
		}

		if (this.className == "article") {
			const article_type = button.dataset.articleType
			if (article_type) {
        if (['audio', 'exhibition'].includes(article_type)) {
          if (article_type == 'exhibition') {
            this.articleFilterType  = 'ausstellung,kabinettausstellung,praesentation'
          } else {
  				  this.articleFilterType = article_type
          }
  			} else {
          this.articleFilterType = null
        }
  		}
    }

		if (this.searchInputTarget.value != "") {
			queryData["query"] = this.searchInputTarget.value }

 		this.element.addEventListener('hidden.bs.modal', e => {
			this.recievingElement.setAttribute('id','')
			this.searchInputTarget.removeEventListener('keyup', this.keyupHandler)
		}, {
		  once: true,
		})

		this.element.addEventListener('shown.bs.modal', e => {
			this.searchInputTarget.focus()
			//TODO Reset and Disconnect?
		}, {
		  once: true,
		})
  }

	keyupHandler(e) {
		if (this.searchInputTarget.value == "" || this.searchInputTarget.value.length < 3) return;
		if (e.which != 224 && e.which != 17 && e.which != 19 && e.which != 18 && e.which != 16 && e.which !== 0 && !e.ctrlKey && !e.metaKey && !e.altKey) {
			this.search()
		}
	}

	search() {
		if (this.className == 'event') {
			this.url = `/events.json?order=desc&query=${this.searchInputTarget.value}`
		}
	
		if (this.className == 'article') {
			this.url = `/articles/search.json?query=${this.searchInputTarget.value}`
			if (this.articleFilterType != null) { this.url += "&a_type=" + this.articleFilterType }
		}

		if (this.className == 'attachment') {
			this.url = `/attachments.json?query=${this.searchInputTarget.value}`
			if (['audio','document','image', 'vimeo'].includes(this.attachmentFilterType)) this.url += "&type=" + this.attachmentFilterType
		}

    fetch(this.url)
    	.then(response => response.json())
			.then(data => this.bodyTarget.innerHTML = this.selection_modal_template(data));
	}

	canSelectMultiple() {
		return this.recievingElement.hasAttribute('multiple')
	}

	capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
	}

	select(e) {
		let class_name = "" 
		if (this.className == "article" || this.className == "event") {
			class_name = "article"
		} else {
			class_name = "attachment"
		}
		let url = "/" + class_name + "s/" + e.currentTarget.getAttribute('id') + "/cms_preview?checkbox_name=" + this.recievingElement.getAttribute('checkbox_name')
		
		const polymorphic_type_input = this.recievingElement.getAttribute('type_input')
		if (polymorphic_type_input) {
			document.getElementById(polymorphic_type_input).value = this.capitalize(class_name)
		}
		
		fetch(url)
			.then(function(response) {
	      return response.text()
	    })
			.then(html => {
				if (this.canSelectMultiple()) { this.previews.insertAdjacentHTML('beforeend', html); }
				else { this.previews.innerHTML = html }
			})
	}

	selection_modal_template(items) {
		if (this.className == "article" || this.className == "event") return this.render_articles_for_selection_modal(items);
		if (this.className == "attachment") return this.render_attachments_for_selection_modal(items);
		return ""
	}

	trigger_selection_modal_search(e) {
		this.attachmentFilterType = e.target.value
		this.search()
	}


	render_articles_for_selection_modal(items) {
		var items_html = "";
		items.forEach( item => {
		  	items_html += "<div data-bs-dismiss='modal' data-action='click->selectionmodal#select' class='linked_item_cms_preview' id='" + item.id + "'>";
		  	items_html += "<div>"
		  		if (item.icon !== undefined) {
		  			items_html += "<img src='" + item.icon + "'>"
		  		} else {
		  			items_html += "<img src='/touch-icon-iphone-retina.png'>" }
			  	items_html += "</div>";
			  	items_html += "<div class='linked_item_cms_preview_text_container'><span>" + item.level + "</span>";
			  	items_html += "<b>" + item.text+"</b>";
			  	items_html += "<br>" + item.subtitle;

			  	items_html += `<br><i>${item.a_type}</i>`;
			  	if (item.start_at !== undefined) {items_html += ": " + item.start_at;}
			  	if (item.type == 'EventRepeat') { items_html += " (Event-Wiederholung)"}
		  	items_html += "</div></div>";
		});
		return items_html;
	}

	render_attachments_for_selection_modal(items) {
		var items_html = "";
		items.forEach( item => {
		  	items_html += "<div data-bs-dismiss='modal' data-action='click->selectionmodal#select' class='linked_item_cms_preview' id='" + item.id + "'><div>";
	  		if (item.icon !== undefined) {
	  			items_html += "<img src='" + item.icon + "'>"
	  		} else {
	  			items_html += "<img src='/touch-icon-iphone-retina.png'>" }
		  	items_html += "</div><div class='linked_item_cms_preview_text_container'>"+item.text+"</div></div>";
		});
		return items_html;
	}

	
}
