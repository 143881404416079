import OpenSeadragon from 'openseadragon/build/openseadragon/openseadragon.min.js'
import { Controller } from "stimulus"

export default class extends Controller {
  viewer = null
  openseadragon = null
  static targets = [ "openseaimage" ]
  connect() {
  	this.close = this.close.bind( this )
  }

  show(e) {
  	if (this.viewer == null) {
	  	let arr = []
	  	this.openseaimageTargets.forEach( i => {
	  		arr.push(`/image-service/${i.dataset.blob}/info.json`)
	  	})
	  	this.openseadragon = document.getElementById('openseadragon')
			this.viewer = OpenSeadragon({
				id: "openseadragon",
				showNavigator:  true,
				prefixUrl: '/openseadragon/images/',
				tileSources: arr,
				defaultZoomLevel: 0.5,
        minZoomImageRatio: 0.3,
				sequenceMode: true,
				showFullPageControl: false,
				navigatorPosition: 'BOTTOM_RIGHT',
				navigationControlAnchor: OpenSeadragon.ControlAnchor.TOP_RIGHT,
				sequenceControlAnchor: OpenSeadragon.ControlAnchor.TOP_RIGHT,
				initialPage: parseInt(e.target.dataset.index)
			})
	    let closeButton = new OpenSeadragon.Button({
	      tooltip: 'Close',
	      srcRest: `/openseadragon/images/fullpage_rest.png`,
	      srcGroup: `/openseadragon/images/fullpage_grouphover.png`,
	      srcHover: `/openseadragon/images/fullpage_hover.png`,
	      srcDown: `/openseadragon/images/fullpage_pressed.png`,
	      onClick: this.close
	    })
	    this.viewer.addControl(closeButton.element, { anchor: OpenSeadragon.ControlAnchor.TOP_RIGHT });
	    const button = closeButton.element;
			const parent = button.parentElement.parentElement;
			parent.append(button)
		} else {
			this.viewer.goToPage(parseInt(e.target.dataset.index))
		}

		this.openseadragon.classList.toggle('hidden')
  }

  close (e) {
  	//hotfix! a crazy form of stopPropagation
  	setTimeout(e => {
	  	this.openseadragon.classList.toggle('hidden')
	  },200)
  }

  
}
