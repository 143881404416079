import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "display", "new", "template"]

  remove(e) {
    let list = this.listTarget.value.split(", ")
    const index = list.indexOf(e.target.dataset.name)
    if (index > -1) { 
      list.splice(index, 1)
      e.target.parentElement.remove()
    }
    this.listTarget.value = list.join(", ")
  }

  addOld(e) {
    if(this.addToList(e.target.dataset.name)) {
      e.target.remove()
    }
  }

  addToList(tagString) {
    let list = this.listTarget.value.split(", ")
    const index = list.indexOf(tagString)
    if (index == -1) {
      list.push(tagString)
      this.listTarget.value = list.join(", ")
      
      const template = this.templateTarget
      const clone = template.content.cloneNode(true)
      let span = clone.querySelector("span")
      span.textContent = tagString
      this.displayTarget.appendChild(clone)

      return true
    }
  }

  addNew() {
    let list = this.listTarget.value.split(", ")
    let newList = this.newTarget.value.split(", ")
    newList.forEach( n => {
      this.addToList(n)
    })
    this.newTarget.value = ""
  }

  updateTag(event) {
    event.preventDefault()
    const form = this.element
    const url = "/admin/tags/"+form.querySelector('.id').value

    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        name: form.querySelector('.name').value,
        name_nl: form.querySelector('.name_nl').value,
        name_en: form.querySelector('.name_en').value
      })
    })
    .then(function(res){ return res.json(); })
    .then(function(data) {
      form.querySelector('.name').value = data.name;
      form.querySelector('.name_en').value = data.name_en;
      form.querySelector('.name_nl').value = data.name_nl;
      form.querySelector('.text-warning').innerHTML = "...gespeichert"
      setTimeout(function(){
          form.querySelector('.text-warning').innerHTML = ""
      }, 2000)
    })
  }
}
