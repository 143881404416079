import { Controller } from "stimulus"
import imagesLoaded from 'imagesloaded'

import * as PhotoSwipe from 'photoswipe'
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'



export default class extends Controller {
	static targets = ["images", "gallerylink"]

	loadedImagesCount = 0;

	props = {
		rowHeight: 180,
		margin: 2,
	}

	connect() {
		this.listenForImagesLoadedAndResize(this.imagesTargets)
    if (this.element.clientWidth < 400) {
      this.props.rowHeight = 100
    }
  }

  listenForImagesLoadedAndResize(items) {
    items.forEach(i => {
      imagesLoaded(i, function(instance) {
        this.loadedImagesCount += 1
        if (this.loadedImagesCount == this.imagesTargets.length) {
        	this.renderThumbs()
        	this.appendImages()
        }
      }.bind(this));
    })
  }

  appendImages() {
  	this.imagesTargets.forEach( image => {
  		var ratio = (image.scaletwidth / this.props.rowHeight);
  		image.style.height = this.props.rowHeight + "px";
  		image.style.margin = this.props.margin + "px"
  		image.style.width = Math.floor(this.props.rowHeight * ratio) + "px";
  		image.classList = ""
  	})
  }


  calculateCutOff (len, delta, items) {
    var cutoff = [];
    var cutsum = 0;
    for(var i in items) {
        var item = items[i];
        var fractOfLen = item.scaletwidth / len;
        cutoff[i] = Math.floor(fractOfLen * delta);
        cutsum += cutoff[i];
    }

    var stillToCutOff = delta - cutsum;
    while(stillToCutOff > 0) {
        for(i in cutoff) {
            cutoff[i]++;
            stillToCutOff--;
            if (stillToCutOff < 0) break;
        }
    }
    return cutoff;
  }


  buildImageRow (items, containerWidth) {
    var row = [];
    var len = 0;
    var imgMargin = 2 * this.props.margin;
    while(items.length > 0 && len < containerWidth) {
        var item = items.shift();
        row.push(item);
        len += (item.scaletwidth + imgMargin);
    }

    var delta = len - containerWidth;
    if(row.length > 0 && delta > 0) {
        var cutoff = this.calculateCutOff(len, delta, row);
        for(var i in row) {
            var pixelsToRemove = cutoff[i];
            item = row[i];
            item.marginLeft = -Math.abs(Math.floor(pixelsToRemove / 2));
            item.vwidth = item.scaletwidth - pixelsToRemove;
        }
    }
    else {
        for(var j in row) {
            item = row[j];
            item.marginLeft = 0;
            item.vwidth = item.scaletwidth;
        }
    }
    return row;
  }

  renderThumbs() {
  	let containerWidth = this.element.clientWidth;
    if (containerWidth == 0) return [];

    var items = this.imagesTargets.slice();
    for (var t in items) {
      this.setThumbScale(items[t]);
    }

    var thumbs = [];
    var rows = [];
    while(items.length > 0) {
        rows.push(this.buildImageRow(items, containerWidth));
    }

    for(var r in rows) {
        for(var i in rows[r]) {
          var item = rows[r][i];
					thumbs.push(item);
        }
    }
    return thumbs;
  }

  setThumbScale (item) {
    item.scaletwidth = Math.floor(this.props.rowHeight * (item.width / item.height));
  }

  onImageClick(event) {
    event.preventDefault()
    const galleryWrapper = document.querySelector('.pswp')
    
    var options = {
      history: false,
      index: this.items.findIndex(item => item.src === event.currentTarget.getAttribute('href'))
    }

    var gallery = new PhotoSwipe(galleryWrapper, PhotoSwipeUI_Default, this.items, options)

    // https://github.com/dimsemenov/PhotoSwipe/issues/741#issuecomment-430725838
    gallery.listen('beforeChange', function() {
      const src = gallery.currItem.src

      const image = new Image()
      image.src = src

      image.onload = () => {
        gallery.currItem.w = image.width
        gallery.currItem.h = image.height

        gallery.updateSize()
      }
    })

    gallery.init()
  }

  get items() {
    return this.gallerylinkTargets.map(function(item) {
      const c = item.getAttribute('data-copyright')
      var t = item.getAttribute('data-title')
      if (c.length > 0) {
        t += "<br>© " + item.getAttribute('data-copyright')
      }
      return {
        src: item.getAttribute('href'),
        title: t,
        w: 0,
        h: 0
      }
    })
  }
}