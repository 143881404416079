import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["audio"]
  static values = { starturl: String }

  connect() {
    this.observeUrlChange = this.observeUrlChange.bind(this)
    document.documentElement.addEventListener( "turbo:visit", this.observeUrlChange)
  }

  observeUrlChange(event) {
    if (document.URL.indexOf("switch_locale") != -1) {
      console.log("uhihiu")
      this.audioTarget.pause()
      this.audioTarget.src = ""
    }
  }

  starturlValueChanged() {
    if(this.starturlValue !== "") {
      this.audioTarget.setAttribute('src', this.starturlValue)
      this.audioTarget.play()
    } else {
      this.audioTarget.setAttribute('src', '')
      this.audioTarget.pause()
    }
  }
}