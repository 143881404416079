import "@hotwired/turbo-rails"
import "controllers"

require("../stylesheets/application.scss");
import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")
// import { German } from "flatpickr/dist/l10n/de.js"

import { Collapse, Modal } from 'bootstrap';



document.addEventListener("turbo:load", () => {
  flatpickr(".datepicking", {
    altInput: true,
    altFormat: "d.m.Y",
    dateFormat: "Y-m-d",
  })

  // forcing to scroll to right pos
  if (window.location.hash){
    setTimeout(() => {
        let tags = document.getElementsByName(window.location.hash.slice(1))
        if(tags.length > 0) {
          tags[0].scrollIntoView(true);
        }
    }, 300)
  }

  var vs = document.querySelectorAll('.vertical_exhibition')
  vs.forEach( v => {
    v.style.left = `-${v.getBoundingClientRect().height+2}px`
  })

  var mi = document.querySelectorAll('.navmodal-link')
  mi.forEach( e => {
    e.addEventListener("click", () => {
      var m = Modal.getOrCreateInstance(document.getElementById('navmodal'))
      if (m) {
        m.hide()
      }
    })
  })
  var w = document.querySelector('send_share_whatsapp')
  if(w) {
    w.addEventListener('click', (ev) => {
      window.location.href = "WhatsApp://send?text=" + ev.currentTarget.dataset.description + '%0A%0ADetails:%0A' + encodeURIComponent(ev.currentTarget.dataset.link);
    })
  }
  
  var s = document.getElementById('send_share_email')
  if(s) {
    s.addEventListener('click', (ev) => {
      window.location.href = 'mailto:?to=&subject=' + ev.currentTarget.dataset.title +'&body='+ ev.currentTarget.dataset.description + '%0A%0ADetails:%0A' + encodeURIComponent(ev.currentTarget.dataset.link);
    })
  }
})
