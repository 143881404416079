import { Controller } from "stimulus"

export default class extends Controller {
  button = null
  connect() {
    this.button =  document.getElementById('goup')
    window.addEventListener("scroll", () => this.goup(), { passive: true })

    setTimeout(function(){
      const a = document.querySelectorAll('#rails-alert')
      a.forEach(e => {
        e.style.visibility = "hidden";
      })
    }, 10000);

    const tabButtons = document.querySelectorAll(".tab-button")
    tabButtons.forEach( b => {
      b.addEventListener("click", ev => {
        const o = ev.target.parentElement.querySelectorAll(".tab-button")
        o.forEach( bb => bb.classList.remove('active'))
        ev.target.classList.add('active')

      })
    })
  }

  goup() {
    const p = window.scrollY
    if (p > 200) {
      this.button.style.display = 'block'
    } else {
      this.button.style.display = 'none'
    }
  }

  top() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
