import { Controller } from "stimulus"

export default class extends Controller {
  //static targets = []

  connect() {
    import("trix")
    document.addEventListener("turbo:submit-end", (e) => {
      setTimeout(() => {
        document.getElementById('turbo_stream_alerts').innerHTML = ""
      }, 5000)

    })
  }

  deleteParagraph(e) {
    const pid = e.currentTarget.dataset.paragraphId
    if (confirm(`Absatz ID(${pid}) löschen?`)) {
      fetch(`/paragraphs/${pid}`, {
        method: 'DELETE',
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
        }
      }).then(_ => {
        const t = document.getElementById(`paragraph-form-${pid}-wrapper`)
        t.style.transition = "all 0.5s ease-out"
        t.style.opacity = 0
        setTimeout(() => {
          t.remove()
        }, 500)
      })
    }
  }

  moveParagraph(e) {
    const direction = e.currentTarget.dataset.direction
    const pid = e.currentTarget.dataset.paragraphId
    fetch(`/paragraphs/${pid}/move/${direction}`, {
      method: 'PATCH',
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(_ => {
      const moving = document.getElementById(`paragraph-form-${pid}-wrapper`)
      const parent = document.getElementById('paragraphs_listing')
      if (direction == 'up') {
        if (moving.previousElementSibling) {
          parent.insertBefore( moving, moving.previousElementSibling)}}
      if (direction == 'down') {
        if (moving.nextElementSibling) {
          parent.insertBefore( moving.nextElementSibling, moving)}
      }
    })
  }

  getFlatpickr() {
    return document.getElementById('published_at_date')._flatpickr
  }

  setDateToToday(e) {
    const flatpickr = this.getFlatpickr()
    if (flatpickr) {
      flatpickr.setDate(new Date, false) }
    document.getElementById('published_at_time').value = '00:00'
    this.setPublishedAt()
  }

  clearDate(e) {
    document.querySelectorAll('#published_at_time, #article_published_at').forEach(e => {e.value = ''})
    const flatpickr = this.getFlatpickr()
    if (flatpickr) {
      flatpickr.clear() }
    document.getElementById('published_at_time').value = '00:00'
  }

  addZero(i) {
    if (i < 10) { i = "0" + i; }
    return i;
  }

  setPublishedAt() {
    const dateInput = document.querySelector("#published_at_date")._flatpickr;
    const time = document.getElementById('published_at_time');
    const publishedAt = document.getElementById('article_published_at')
    const d = dateInput.selectedDates[0]
    if (d) {
      const h = time.value.split(':')[0];
      const min = time.value.split(':')[1];
      d.setHours(h,min);
      const res = `${d.getUTCFullYear()}-${this.addZero(d.getUTCMonth()+1)}-${this.addZero(d.getUTCDate())} ${this.addZero(d.getUTCHours())}:${this.addZero(d.getMinutes())}:00`
      publishedAt.value = res
    } else {
      publishedAt.value = ""
    }
  }

  addEventRepeat(e) {
    const articleId = e.target.dataset.articleId
    const start_date = document.getElementById('new_event_repeat_start_date').value
    const start_time = document.getElementById('new_event_repeat_start_time').value
    const end_time = document.getElementById('new_event_repeat_end_time').value
    
    const xhttp = new XMLHttpRequest()
    xhttp.onload = function() {
      document.getElementById('event_repeats').innerHTML = xhttp.response
    }
    xhttp.open("POST", `/articles/${articleId}/event_repeats/`)
    xhttp.setRequestHeader("X-CSRF-Token", document.querySelector('meta[name="csrf-token"]').content)
    xhttp.setRequestHeader("Content-Type", "application/json")
    xhttp.send(JSON.stringify(
      {
        'event_repeat': {'start_date' : start_date, 'start_time': start_time, 'end_time': end_time}}))
  }

  removeEventRepeat(e) {
    fetch(e.target.dataset.url, {
      method: 'DELETE',
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => {
      return response.text()
    }).then(html => {
      document.getElementById('event_repeats').innerHTML = html
    })
  }

  enableInputsOfParent(e) {
    e.currentTarget.parentElement.querySelectorAll('input').forEach(e => { e.removeAttribute('disabled') })
  }

  removeLinkedItem(e) {
    e.target.parentElement.parentElement.remove()
  }

  moveLinkedItemUp(e) {
    this.moveLinkedItem(e.target, -1);
  }

  moveLinkedItemDown(e) {
    this.moveLinkedItem(e.target, 1);
  }

  moveLinkedItem(button, direction) {
    var wrapper = button.parentElement.parentElement.parentElement
      if (direction == -1 ){
        if (wrapper.previousElementSibling) {
          wrapper.parentNode.insertBefore(wrapper,wrapper.previousElementSibling); return; }}
      if (direction == 1) {
        if (wrapper.nextElementSibling) {
          wrapper.parentNode.insertBefore(wrapper.nextElementSibling, wrapper)
        }
        
      }
  }

  levelChanged(e) {
    const currentLevel = parseInt(e.target.value)
    document.getElementById('article_a_type').value = ""
    this.disableEventDetails()

    if (currentLevel == 1) {
      document.getElementById('a_type_row').style.display = 'none'
      this.enableImageDownloadDeadline()
    } else {
      document.getElementById('a_type_row').style.display = 'flex'
      this.disableImageDownloadDeadline()
    }
    const options = document.getElementById('article_a_type').querySelectorAll('option')
    options.forEach(opt => {
      if(currentLevel >= parseInt(opt.dataset.minLevel)) {
        opt.removeAttribute('disabled');
      } else {
        opt.setAttribute('disabled','disabled');
      }
    })
  }

  typeChanged(e) {
    const select = e.target
    if (select.options[select.selectedIndex].classList.contains('is_event')) {
      enableEventDetails();
    } else {
      disableEventDetails();
    }

    var a_type = document.getElementById('article_a_type').value

    if (a_type == "press") this.enableImageDownloadDeadline()
    else this.disableImageDownloadDeadline(); 

    if (a_type == "publikation") enablePublishedAtInput()
    else this.disablePrintPublishedAtInput()
  }

  disableEventDetails(){
    document.getElementById('event_details_row').style.display = 'none'
    document.getElementById('article_start_date').setAttribute('disabled', 'disabled');
    document.getElementById('article_start_time').setAttribute('disabled', 'disabled');
    document.getElementById('article_end_date').setAttribute('disabled', 'disabled');
    document.getElementById('article_end_time').setAttribute('disabled', 'disabled');
  }
  
  enableEventDetails(){
    document.getElementById('event_details_row').style.display = 'block'
    document.getElementById('article_start_date').removeAttribute('disabled');
    document.getElementById('article_start_time').removeAttribute('disabled');
  }

  enableImageDownloadDeadline() {
    document.getElementById('image_download_deadline_wrapper').style.display = 'block'
    const i = document.getElementById('article_image_download_deadline')
    i.removeAttribute('disabled')
    i.nextElementSibling.removeAttribute('disabled')
  }

  disableImageDownloadDeadline() {
    document.getElementById('image_download_deadline_wrapper').style.display = 'none'
    const i = document.getElementById('article_image_download_deadline')
    i.setAttribute('disabled','disabled')
    i.nextElementSibling.setAttribute('disabled','disabled')
  }

  enablePrintPublishedAtInput() {
    document.getElementById('print_published_at_row').style.display = 'block'
    document.getElementById('article_print_published_at').removeAttribute('disabled');
  }

  disablePrintPublishedAtInput() {
    document.getElementById('print_published_at_row').style.display = 'none'
    document.getElementById('article_print_published_at').setAttribute('disabled','disabled');
  }
}
